import { keyframes } from 'styled-components';

export const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-50vw);
  }
  100% {
    transform: translateX(0);
  }
`;

export const slideInFromBottom = keyframes`
  0% {
    transform: translateY(50vh);
  }
  100% {
    transform: translateY(0);
  }
`;

export const hoverOption = keyframes``;
