import React from 'react';
import styled from 'styled-components';

export default function Header() {
  return (
    <Ctr>Julian Jacobs</Ctr>
  );
}

const Ctr = styled.div`
  height: 50px;
  width: 100%;
  border-bottom: solid 3px #1E5C7E;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
`;
