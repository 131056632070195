import React, { useState } from 'react';
import styled from 'styled-components';
import history from '../../history';

import { Header } from '../../components';

export default function Blog() {
  return (
    <Ctr>
      <Header />
      
      <h4>Blog Posts</h4>

      <BlogPreviewCtr>
        <Preview onClick={() => history.push('/blog/HelloWorld')}>
          <h1>Hello World</h1>
          <p>Why is this here and what am I doing?</p>
        </Preview>
      </BlogPreviewCtr>
    </Ctr>
  );
}

const Ctr = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 750px;
  min-height: 100vh;

  margin: 0 auto;
`;

const BlogPreviewCtr = styled.div`
  height: 100%;
`;

const Preview = styled.div`
  height: 200px;
  width: 95%;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
`;
