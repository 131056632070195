import React from 'react';
import styled from 'styled-components';

export default function P({ children }) {
  return <Ctr>{children}</Ctr>;
}

const Ctr = styled.p`
  padding: 5px 5px 0 5px;
`;
