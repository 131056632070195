import React from 'react';
import styled from 'styled-components';

import {
  Ctr,
  H1,
  H2,
  P,
  Section
} from '../../../components';

export default function HelloWorld() {
  return (
    <Ctr>
      <Content>
        <TitleCtr>
          <H1>Hello World</H1>
        </TitleCtr>

        <Section paddingTop="0px">
          <H2>What this is about</H2>
          <P>
            This is where I write about stuff. This is my personal database of knowledge, I hope you find it useful.
          </P>
        </Section>

        <Section>
          <H2>If you found yourself here</H2>
          <P>
            You probably found the wrong Julian Jacobs. <a href="https://www.facebook.com/julian.jacobs.39">I'm this one</a>.
            I started a web development contract in which I got to use AWS Amplify again. Got super hype and decided to <strong>finally</strong> make my personal website. Whatever this will turn out to be.
            This will forever be a work in progress. Written for no one, just another person trying to figure out life.
          </P>
        </Section>

        <Section>
          <H2>Why now?</H2>
          <P>
            I've had a lot of time to think about things I want in my life throughout these past Covid months.
            This is an opportunity for me to organize my life, and do so in public. Why?
            Because I'm currently in the process of <a href="https://en.wikipedia.org/wiki/Individuation">personal indivuation</a> and <a href="https://www.youtube.com/watch?v=tIoJhqnOc0M&t=9s">integrating my shadow</a>.
            I feel pent up, but hidden behind a self-imposed cage. One that is fueled and validated by external sources, and this is the process I am attempting to get through.
            Putting a work in progress publically displayed to the world is slightly concering, but in working through these things I am more hopeful and confident I will get whatever vague, ambiguous result I'm looking for out of this experience.
          </P>
        </Section>

        <Section>
          <H2>I actually became and engineer when...</H2>
          <P>
            This story will eventually have an entire blog post devoted to it I'm sure. But in the meantime.<br></br>
            I started my professional career in a startup, in which I was the CTO and lead developer. We released an app that gained some notoriety, eventually landing us a private investment that would change our lives forever.
            Jazzed about this thing I created with friends, I turned down my initial employment offer to work for Qualcomm, in the face of 6 figure student loan debt and little to no experience in web development.
            Taking this plunge would turn out to be one of the greatest decisions of my life. It has instilled in me a confidence and awareness of my personally intellectual capabilities, as well as an attitude to start up whenever given the opportunity.
            Weaponized with this confidence, when I decided to leave the company in December 2019 due to financial constraints, I went from being a hotshot lead dev at a consumer centric young startup to a dev ops engineer in a family owned small company.
            My ego damaged, yet still confident in my abilities, I eagerly worked to make a name for myself, getting any high visibility project I could get my hands on and fighting with anyone in my way.
            Little did I know, I was the polar opposite of a team player. Starting a company and calling the shots gave me a swagger and technical landscape awareness that was pivotal to my growth, yet disastrous for my professional personality.
            I hated anything my boss and teammates suggested not because I did not respect them or their intelligence, but because I did not in fact respect myself.
            I was so fearful of anyone seeing me for the real me, the scared kid with way too many loans to be taking the risks he was taking, exposed as the same person that struggles to keep up or assert himself.
            But overtime (and I'm still actively in the process) I've grown some professional courtesy and personality. I've seen the value in cooperation and teamwork, transparancy and communication.
            I've seen the necessity of doing what you have to do to get the ball rolling. And that to move anywhere in life, It really takes the first steps.
            I am one to let judgement, most specicially the fear of it, stop me from doing things like the website, and just things in general at all.
            At the original company, we always had a saying I never followed to the genuine core: <i>iteration over perfection</i><br></br>
            To summarize: the day I actually becamse and engineer was when I learned the value of team work and incremental progress. Do not abandon everything because it's not actualizing at the rushed rate you intend for it to.
            Be patient, take it day by day, face reality, and truly accept the consequences of your actions.
          </P>
        </Section>

        <Section>
          <H2>The Shadow</H2>
          <P>
            One of the things I've realized is how ashamed of my "shadow" side of my personality I've become.
            This is an attempt to utilize a public space to air out some of my thoughts and opinions.
            I've always striven for perfection, anything else I abandon in shame.
            This is an attempt to get some thoughts out there, organize myself, and finally start telling my story.
            My painfully imperfect story.
          </P>
        </Section>

        <Section>
          <H2>Data storage</H2>
          <P>
            The technological age seems to be our information reniassance.
            We are entering a space where we can persist our thoughts and feelings [cost, storage and computationally] effectively and efficiently.
            Would <a href="https://en.wikipedia.org/wiki/Marcus_Aurelius">Marcus Aurelius</a> have collected his (accidental?) masterpiece <i>Meditations</i> in a google drive given the chance?
            I spend so much time thinking. About everything. The weather, my future, my mortality. How I'm perceived. The quality and accuracy of my perception of reality.
            I've always been an introspective individual, and inconsistently tried working out my inner demons with pen and paper but find myself abandoning journals. Let alone spending the actual time to sift and search through them.
            And sure, twitter does this. But I don't even know how I like to think, always in fear of how my public posts will be judged. This seems to be a safe space I alone can ponder all the aspects of my life.
            Because you do not need to come to this page. You do not have to read this blog, or my stream of consciousness (coming out soon - s/o to CJ for this <a href="https://stream.cjpais.com/">great idea</a>)
          </P>
        </Section>

        <Section>
          <H2>Learning by doing</H2>
          <P>
            Spent a lot of time agonizing and stressing over things out of my control.
            There is so much live I've longed to live, yet let my fears keep my from ever trying.
            There are so many stories I've wanted to tell, but let my anxiety of judgement silence my words.
            So here I sit before you, taking a step toward focusing on my self-development rather than how I'm perceived by the external world.
          </P>
        </Section>

        <Section>
          <H2></H2>
        </Section>

      </Content>
    </Ctr>
  );
}

const Content = styled.div``;

const TitleCtr = styled.div`
  text-align: center;
`;
