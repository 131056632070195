/**
 * Basic Container component that handles sizing and layout for (currently)
 * blog pages on the app.
 */
import React from 'react';
import styled from 'styled-components';
import { Header } from '.';

export default function Ctr({ children }) {
  return (
    <Div>
      <HeaderCtr>
        <Header />
      </HeaderCtr>

      {children}
    </Div>
  );
}

const Div = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 750px;
  min-height: 100vh;

  margin: 0 auto;
`;

const HeaderCtr = styled.div`
  padding-bottom: 10px;
`;
