import React from 'react';
import styled from 'styled-components';

export default function H1({ children }) {
  return <Ctr>{children}</Ctr>
}

const Ctr = styled.h1`
  font-size: 2.5rem;
`;
