import React from 'react';
import './App.css';

import {
  // BrowserRouter as Router,
  Router,
  Route,
  Switch
} from 'react-router-dom';
import history from './history';

import Home from './pages/Home/Home';
import Blog from './pages/Blog/Blog';
import NotFound from './pages/Misc/NotFound';

import BlogPages from './pages/Blog/BlogPages';

function App() {
  return (
    <Router history={history}>
      <div>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/blog' component={Blog} />
          <Route exact path='/blog/:story' render={(p) => {
            if (!(p.match.params.story in BlogPages)) {
              history.push('/notfound');
              return <></>;
            }
            const Story = BlogPages[p.match.params.story];
            return <Story />;
          }} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
