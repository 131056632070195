import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { slideInFromLeft, slideInFromBottom } from './HomeAnimations';
import { API, graphqlOperation } from 'aws-amplify';
import history from '../../history';

const query = `
  query {
    listBlurbs {
      items {
        id, text, createdAt, updatedAt
      }
    }
  }
`;

export default function Home() {
  const [blurbs, setBlurbs] = useState([]);

  // bring this back when it's time to activate your stream of consciousness
  // useEffect(() => {
  //   API.graphql(graphqlOperation(query))
  //     .then((d) => {
  //       setBlurbs(d.data.listBlurbs.items);
  //     })
  // }, []);

  const sendMessage = () => {
    console.log("sending message");
  }

  return (
    <Ctr>
        <Content>
          <Name>Julian Jacobs</Name>
          <NavCtr>
            {/* <Option>Resume</Option> */}
            {/* <Option onClick={() => history.push('/blog')}>Blog</Option> */}
            {/* <Option>Stream</Option> */}
            <Option>
              coming soon :)
              {/* <input type="text" placeholder="join the chat :)" />
              <button onClick={sendMessage}>send it</button> */}
            </Option>
          </NavCtr>
        </Content>

        <Stream>
          {blurbs.map((b, idx) => (
            <p key={idx}>{b.text}</p>
          ))}
        </Stream>
    </Ctr>
  );
}

const Ctr = styled.div`
  height: 100vh;
  width: 100%;
`;

const Content = styled.div`
  position: absolute;
  /*top: 46%;*/
  left: 10px;
  bottom: 20px;
`;

const Name = styled.div`
  font-size: 5.75rem;
  animation-name: ${slideInFromLeft};
  animation-duration: 2s;
`;

const NavCtr = styled.div`
  margin-top: 5px;
  margin-left: 60px;
  border-left: solid 3px #1E5C7E;
  padding: 1px 17.5px;
  animation-name: ${slideInFromBottom};
  animation-duration: 2s;
`;

const Option = styled.div`
  font-size: 2rem;
  margin: 15px 0;
  cursor: pointer;
`;

const Stream = styled.div`
  position: absolute;
  width: 25%;
  right: 20px;
`;