import React from 'react';
import styled from 'styled-components';

export default function Section({ paddingTop, children }) {
  return <Ctr paddingTop={paddingTop}>{children}</Ctr>;
}

const Ctr = styled.div`
  padding: ${props => props.paddingTop ? props.paddingTop : '20px'} 0 20px 0;
`;
