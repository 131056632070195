import React from 'react';
import styled from 'styled-components';

export default function H2({ children }) {
  return <Ctr>{children}</Ctr>
}

const Ctr = styled.h2`
  font-size: 2rem;
`;
