import React from 'react';
import styled from 'styled-components';

export default function NotFound() {
  return (
    <Hero>
      <span>Page Not Found</span>
    </Hero>
  );
}

const Hero = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`